import {Person} from "../../models/person.model";
import {UserRole} from "../../enums/user-role.enum";
import {ParticipantState} from "../../enums/participant_state.enum";

export class AvatarUser {
  person: Person;
  role?: UserRole;
  checked?: boolean = false;
  warning?: boolean = false;
  state?: ParticipantState;

  constructor(person: Person, role?: UserRole, checked: boolean = false, warning: boolean = false,
              state: ParticipantState = ParticipantState.PENDING) {
    this.person = person;
    this.role = role;
    this.checked = checked;
    this.warning = warning;
    this.state = state;
  }
}
